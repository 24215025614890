@import "../Variables";

.customer-visit {
  .content-box--title-box {
    margin: 10px 0;

    h1 {
      font-size: 18px;
    }

    i {
      font-size: 12px;
    }
  }

  @include phone {
    .add-visit {
      .col-md-6, .col-md-12 {
        width: 100%;
      }
    }
  }

  .btn {
    width: 100%;
    margin: 3px 0;
  }

  .row.contact-buttons {
    margin: 0 0 15px 0;
  }

  .notice {
    margin: 0 0 10px 0;
    width: 100%;
  }

  .add-visit {
    .field {
      width: 100%;
    }
  }

  .visit-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      background: url('/assets/icons/tick.svg') no-repeat right 10px;
      list-style-type: none;
      margin: 0;
      padding: 5px 0;
      border-top: #cecece solid 1px;

      .name {
        display: block;
        text-align: center;
      }

      .time {
        margin: 3px 0;
        display: block;
        text-align: center;
      }

      .btn {
        min-height: 20px;
      }
    }
  }
}