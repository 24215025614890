@import '../../../Variables';

.amount-holder {
  display: flex;
  margin: 20px 0;
  width: 100%;
  justify-content: space-evenly;
  gap: 20px;
  flex-direction: row;


  .field--text {
    margin: 0 !important;
    width: 70%;
    .field__element-wrap {;
      position: relative;
        &:after {
          content: '£';
          display: block;
          position: absolute;
          top: 15px;
          left:15px;
          color: #000;
        }
        input {
          text-indent: 10px;
        }
    }
    @include phone {
      //width: 100%;
    }
  }

  .btn-group {
    @include phone {
      //width: 100%;
    }
    .btn:before, .btn:after {
      display: none;
    }
    display: flex;
    width: 30%;
    justify-content: space-between;

    button {
      //height: 100%;
    }
  }
  @include phone {
    flex-direction: column;
    .btn-group, .field--text {
      width: 100%;
    }
  }
}