@import './../../Variables.scss';

body.revolution {
  .rbg-modal {
    font-family: 'Poppins', 'sans-serif';
  }
}

.ReactModal__Body--open {
  .rbg-modal {
    display: block;
  }
}

.rbg-modal {
  display: none;

  h1, h2, h3 {
    margin: .8em 0;
  }

  .modal--overlay {
    position: fixed;
    z-index: 10;
    padding-top: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    .modal--modal {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 80%;
      max-height: 100%;
      outline: none;
      position: fixed;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);

      .loading-icon {
        margin: 0 auto;
        text-align: center;

        i {
          font-size: 100px;
        }
      }

      .modal__content {
        margin: 30px 0 80px 0;
        max-height: 700px;
        // overflow-y: auto;
      }

      .modal__top-buttons {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        padding: 0;
        margin: 0;
        background: none;

        button {
          font-size: 22px;
          background: none;
          margin: 3px;
          position: relative;

          .button-action {
            border-radius: 4px;
            &:before {
              content: '';
              position: absolute;
              top: -23px;
              border: solid 15px transparent;
              border-bottom-color: #000;
              left: 30px;
              z-index: 98;
            }
            z-index: 99;
            position: absolute;
            top: 40px;
            background: #000;
            color: #FFF;
            left: -30px;
            padding: 5px;
            a {
              margin: 5px 0;
              z-index: 100;
              display: block;
              width: 75px;
              font-size: 12px;
              color: #FFF;
              text-decoration: none;
              font-weight: 600;
            }
          }
        }
      }
    }

    .modal__buttons {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 5px 0;
      background: #FFF;
      left: 0;
      display: flex;
      flex-direction: row;
      border-top: #cecece solid 1px;

      button {
        flex-grow: 1;
        margin: 0 10px;
      }
    }
  }
}

.rbg-modal.rbg-modal--full-page {
  @include phone {
    .modal--overlay {
      .modal--modal {
        padding: 10px;

        &.has-buttons {
          .modal__content {
            max-height: 600px;
            margin: 0 0 10px 0;
            padding: 0 10px;
            overflow-y: scroll;

            .edit-booking-details {
              margin: 0 10px;
            }
            
            .intro__content {
              font-size: 15px;
            }

            .react-datepicker-popper > div {
              padding-left: 50px;
  
              .react-datepicker__triangle {
                left: 120px !important;
              }
            }
          }
        }
      }
    }
  }
}
@include phone {
  .rbg-modal.rbg-modal--full-page {
    .modal--overlay {
      top: 0;
      margin: 0;
      left: 0;
      padding: 0;

      .modal--modal {
        transform: none;
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 40px 0 0 0;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        left: 0;
        top: 0;
        transform: none;

        .modal__content {
          margin: 30px 0 0 0;
          max-height: 85%;
          padding: 0 20px;
          overflow-y: auto;
        }
      }
    }
  }
  .rbg-modal.rbg-modal--fix-bottom {
    .modal--overlay {
      .modal--modal {
        position: fixed;
        bottom: 0;
        top: auto;
        width: 100%;
        padding: 30px 0 20px 10px;
      }
    }
  }
}
@include tablet {
  .rbg-modal {
    .modal--overlay {
      .modal--modal {
        .modal__content {
          height: 500px;
          margin-bottom: 0;
          padding-bottom: 50px;
          overflow-y: scroll;

          .react-datepicker-popper > div {
            padding-left: 50px;

            .react-datepicker__triangle {
              left: 120px !important;
            }
          }
        }
      }
    }
  }
}

.rbg-modal.rbg-modal--add-edit-options {
  .ReactModal__Content {
    @include phone {
      width: 85%;
    }
    @include tablet {
      width: 75%;
    }
    width: 50%;
    .modal__content {
      height: 95%;
      .add-edit-options-qty-selector {
        float: right;
        @include phone {
          float: none;
          padding: 10px 0;
        }
      }
    }
  }
}