.switch-container {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;

  // label position
  &.top {
    flex-direction: column;
  }
  &.right {
    flex-direction: row-reverse;
  }
  &.bottom {
    flex-direction: column-reverse;
  }

  .switch {
    display: inline-block;
    font-size: 20px;
    height: 1em;
    width: 2em;
    background: #BDB9A6;
    border-radius: 1em;
    transition: background-color 0.3s ease-in;
    cursor: pointer;


    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    div {
      height: 1em;
      width: 1em;
      border-radius: 1em;
      background: #FFF;
      box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      transition: all 300ms;
    }

    &:has(input:checked) {
      background-color: forestgreen;
      transition: background-color 0.3s ease-in;
    }

    input:checked + div {
      -webkit-transform: translate3d(100%, 0, 0);
      -moz-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }
}