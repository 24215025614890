.rbg-modal .my-details, .portal-page .my-details {
  .field {
    margin-bottom: 10px;
  }

  .my-details--allergens, .my-details--optins {
    border-top: #cecece solid 1px;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    p {
      width: 100%;
    }
    .field {
      width: 33%;
    }
  }

  .modal--buttons {
    .btn--save {
      float: right;
    }
  }
}