.modal__content {
  margin: 0 !important;
  max-height: 90% !important;
  padding-bottom: 20px;
}

.modal--modal {
  max-width: 800px !important;
}

@media screen and (min-width: 600px) {
  .modal__buttons {
    justify-content: flex-end !important;

    button {
      flex-grow: unset !important;
      min-width: 300px;
    }
  }
}
