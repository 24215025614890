@import './../../../../../Variables';

.booking-overview {
  padding: 10px;
  @include phone {
    padding-top: 20px;
  }

  .booking-overview__name {
    font-size: 28px;
    display: block;
    overflow: hidden;
    font-weight: 600;

    @include phone {
      font-size: 23px;
    }
  }

  .booking-overview__ref {
    font-size: 12px;
  }

  .booking-overview__status {
    font-size: 11px;
    margin-left: 10px;
    padding: 3px;
    border-radius: 10px;

    &.booking-overview__status-1 {
      background: green;
      color: #FFF;
    }

    &.booking-overview__status-0, .booking-overview__status-3 {
      background: orange;
    }

    &.booking-overview__status-4 {
      background: red;
    }

    &.booking-overview__status-5 {
      background: white;
      border: #000 solid 1px;
    }
  }

  .action-buttons {
    .btn {
      margin: 0 5px;
      @include phone() {
        width: 100%;
        margin: 5px 0;
      }
    }
    .btn--cancel-booking {
      background-color: #fff;
    }
  }

  .booking-actions {
    margin: 10px 0;
    border: #cecece solid 1px;
    border-radius: 4px;
    background: #FFF;

    .booking-actions--action {
      padding: 10px;
      border-bottom: #cecece solid 1px;

      &:last-child {
        border-bottom: none;
      }

      a {
        display: flex;
        justify-content: space-between;
        color: #000;
        text-decoration: none;
        height: 32px;
        line-height: 32px;
        font-weight: 600;
      }
    }
  }

  .notice--login-to-see {
    margin-top: 20px;
    text-align: center;
    .btn {
      margin: 0 auto;
      display: block;
    }
  }


  .booking-info.grid {
    .grid__item {
      flex-grow: 0;
      width: 50%;
    }
  }

  .row .booking-info {
    font-size: 14px;
  }

  .booking-info {
    margin: 20px 0;

    &.grid {
      align-items: center;
      justify-content: space-between;
      
      @include phone {
        flex-wrap: wrap;
      }
    }

    .booking-info__item {
      width: 50%;
      @include desktop {
        width: 20%;
      }

      span {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        margin: 4px 0;
        vertical-align: top;

        img {
          margin-right: 5px;
        }
      }
    }

    .booking-info--people,
    .booking-info--balance {
        span {
          @include phone {
          justify-content: flex-end;
        }
      }
    }
  }
}