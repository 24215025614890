.select-callback {
  ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  button {
    border: #cecece solid 1px;
    padding: 5px;
    margin: 5px 0;
    background: none;
    width: 100%;
  }
}