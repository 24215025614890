.collapse {
  margin-top: 8px;
  list-style: none;

  &-title {
    margin: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span {
      text-transform: uppercase;
    }

    .fa {
      font-size: 30px;
    }
  }
  &-children-wrapper {
    overflow: hidden;
    transition: height 0.3s ease-out;
    //margin-left: 20px;
    margin-top: 10px;

    .children {
      height: fit-content;
    }
  }
}