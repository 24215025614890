$animation: .3s 0s cubic-bezier(.55, 0, .1, 1);

#drawer {
  position: fixed;
  top: 0;
  left: -270px;
  bottom: 0;
  width: 270px;
  background-color: white;
  z-index: 3;
  transition: transform $animation;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
  0 6px 30px 5px rgba(0, 0, 0, 0.12),
  0 8px 10px -5px rgba(0, 0, 0, 0.4);

  &.open {
    transform: translate(calc(270px), 0);
  }

  .drawer-content {
    overflow-y: auto;
    height: 100vh;
    padding: 20px 0;
  }

  .handle {
    position: absolute;
    top: 50%;
    right: -34px;
    padding: 10px;
    background-color: #164464;
    color: #fff;
    border-radius: 0 10px 10px 0;
    z-index: 5;

    &:before, &:after {
      content: '';
      position: absolute;
      display: block;
      width: 10px;
      height: 30px;
      left: 0;
      background-color: #164464;
    }

    &:before {
      top: -30px;
      border-radius: 0 5px 0 0;
    }

    &:after {
      top: 39px;
      border-radius: 0 0 5px 0;
    }
  }
}
.drawer-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.1s, opacity 0.1s linear;
}
