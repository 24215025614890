//html, body, .rbg-app {
//  height: 100%;
//}

.student-signup {
  margin: 20px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    text-align: center;
  }

  &__field-group {
    margin: 16px 0 32px;
  }
}

.graduate-signup {
  padding: 20px;
}