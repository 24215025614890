@import './../../../Variables';

.choose-callback {
  .change-callback {
    font-style: italic;
    font-weight: 600;
    padding: 2px 0;
    display: block;
  }
}

.rbg-modal {
  @include phone {
    .choose-callback-time {
      max-height: 500px;
      overflow: scroll;
      .timeslot {
        width: 100%;
      }
    }
  }
}

.brunch-upgrade-choose-callback-time {
  .timeslot--deselected {
    background: #1dbf8b !important;
    opacity: 0.4;
    span {
      text-decoration: none!important;
      color: white!important;
    }
    span:last-child {
      background: #17926c!important;
    }
  }
}