.data-collection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin: 20px;

  .field.field--bar_id {
    width: 100%;
  }

  .field, &__group, .select2-wrapper {
    width: 49%;
  }

  .dob-picker {
    width: 49%;
    .field {
      width: 100%;
    }
  }

  &__group {
    margin-bottom: 16px;

    .field, .dob-picker {
      width: 100%;
      margin-bottom: 0;
    }

    small {
      font-size: 0.8em;
    }
  }

  .select2-wrapper {
    margin: 0 0 10px 0;
  }

  .opt-ins {
    p {
      font-size: 0.9em;
      text-align: center;
    }

    &__fields {
      display: flex;
      justify-content: center;

      .field {
        width: 20%;

        .field__sub-label {
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .field.field--bar_id {
      width: 100%;
    }
    .field, .select2-wrapper, .dob-picker, &__group {
      width: 100%;
      max-width: 100%
    }
    .field.field--checkbox {
      width: 33%;
    }
    .field--forename, .field--surname, .dob {
      width: 100%;
      max-width: 100%
    }
  }
}

.privacy-statement {
  margin: 32px 20px 16px;
  text-align: center;
  font-size: 0.8em;

  a {
    text-decoration: none;
    color: #CC3A60;

    &:hover {
      text-decoration: underline;
    }
  }
}