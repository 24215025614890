@import './../../../Variables';

body.decuba {
  .booking-tabs {
    .booking-tabs--tab {
      font-size: 15px;
      background: rgba(255, 255, 255, 0.60);
      border: 1px solid #d1d1d1de;

      @include phone {
        border: 1px solid transparent;
      }
    }
  }
}

.booking-tabs {
  width: 100%;

  .booking-tabs--tabs {
    list-style-type: none;
    display: block;

    .booking-tabs--tab {
      display: inline-block;
      font-weight: 400;
      padding: 0 0 10px 0;
      margin: 0 10px;

      &.active {
        .bookings-tabs--tab--icon {
          &.icon--overview {
            background-image: url('/assets/icons/hover/Overview.svg');
          }

          &.icon--guests {
            background-image: url('/assets/icons/hover/Guests.svg');
          }

          &.icon--packages {
            background-image: url('/assets/icons/hover/Package.svg');
          }

          &.icon--payment {
            background-image: url('/assets/icons/hover/Payment.svg');
          }
        }
      }

      .bookings-tabs--tab--icon {
        margin: 0 auto;
        display: block;
        width: 40px;
        height: 40px;
        background-size: 40px 40px;
        background-repeat: no-repeat;

        &.icon--overview {
          background-image: url('/assets/icons/Overview.svg');
        }

        &.icon--guests {
          background-image: url('/assets/icons/Guests.svg');
        }

        &.icon--packages {
          background-image: url('/assets/icons/Package.svg');
        }

        &.icon--payment {
          background-image: url('/assets/icons/Payment.svg');
        }
      }

      svg, img, object {
        margin: 0 auto;
        display: block;
        width: 40px;
        height: 40px;
      }
    }
  }
}

.booking-tabs {
  @include tablet {
    height: 100px;
    margin-top: 30px;
    margin-bottom: 20px;

    .booking-tabs--tabs {
      text-align: center;

      .booking-tabs--tab {
        width: 100px;
        font-size: 13px;
        letter-spacing: 1px;
        border-radius: 5px;
        background-position: 0px 0px;
        background: rgba(255, 255, 255, 0.50);
        border: 1px solid #e6e7eb9f;
        transition: all .2s ease-in;

        a {
          color: #000;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
          
          span:hover {
            color: #000 !important;
          }
        }
        &:hover {
          -webkit-box-shadow: 0px 0px 15px 1px  rgba(0, 0, 0, 0.10);
          -moz-box-shadow: 0px 0px 15px 1px  rgba(0, 0, 0, 0.10);
          box-shadow: 0px 0px 15px 1px  rgba(0, 0, 0, 0.10);
          background: rgba(255, 255, 255, 0.70);
          border: 1px solid transparent;
          transition: all .2s ease-in;
        }
        &.active {
          background: #fff;
          -webkit-box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.15);
          border: 1px solid transparent;
          font-weight: 600;

          a {
            color: #fff;
          }

          &:hover {
            border: 1px solid transparent;
          }
        }
      }
    }
  }
}

.booking-tabs {
  @include phone {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;

    .booking-tabs--tabs {
      height: 70px;
      background: #FFF;
      text-align: center;
      padding: 10px 0 0 0;
      border-top: #cecece solid 1px;

      .booking-tabs--tab {
        width: 20%;
        font-size: 12px;

        .booking-tabs--tab--icon {
          display: block;
          height: 60px;
        }

        a {
          color: #000;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }

        &.active {
          a {
            color: #FA8C24;

            svg {
              fill: #FA8C24;
            }
          }
        }
      }
    }
  }
}