.portal-page--register {
  .field {
    margin: 10px 0;
  }

  .btn {
    width: 100%;
    margin: 10px 0;
  }
  .notice {
    margin: 10px 0;
  }
}