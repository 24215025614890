@import "./../../../Variables";

.not-available {
  button {
    margin: 5px 0;
    width: 100% !important;
  }
}
.step--time-select {
  .btn--submit-enquiry {
    width: 100%;
    margin: 10px auto;
  }
  .notice--fully-booked {
    margin: 0 0 10px 0;
  }
}
.slot-holder {
  background: #FFF;
  border: #e9e9e9 solid 2px;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  .time-slot-holder {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .key-holder {
    display: block;
    width: 100%;
    height: 40px;
    text-align: center;

    .key-holder__items {
      text-align: center;
      .key {
        display: inline-block;
        &.key--available {
          .key--indicator {
            background: #1dbf8b;
          }
        }

        &.key--unavailable {
          .key--indicator {
            background: #cecece;
          }
        }
        .key--indicator {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin: 0 5px;
          border-radius: 4px;
        }
        .key--text {
          display: inline-block;
          margin: 0;
          padding: 0;
          vertical-align: top;
        }
      }
    }
  }
  .time-nav {
    margin: 10px 0;

    .time-nav--left {
      float: left;

      button {
        margin-left: 20px;
      }
    }

    .time-nav--right {
      float: right;
      margin-right: 20px;
    }

    button {
      border: 0;
      padding: 0;
      margin: 0;
      background: none;
    }

    &:after {
      content: '';
      display: inline-block;
    }
  }
}

body.revolution, body.decuba {
  .notice.notice--no-availability, .notice.notice--not-available {
    .btn {
      width: 100%;
      margin: 10px 0;
    }
  }
}

.day-part-selector {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  gap: 15px;
  padding: 15px 10px;
  align-items: center;

  .btn {
    width: 100%;
  }


  @include phone {
    flex-wrap: wrap;
    padding: 0;
    gap: 5px;
    justify-content: space-between;
    .btn {
      width: 49%;
      @include half_phone {
        width: 48.5%;
      }
    }
  }

  .btn--outline:hover {
    color: #e67aa7;
  }
  .btn--disabled {
    cursor: not-allowed;
    background-color: #f4f4f4;
    color: #AAAAAA;
    border: 1px solid #AAAAAA;
    &:hover {
      color: #AAAAAA;
    }
  }
}

.type-select-button {
  button {
    margin-top: 15px;
    width: 100%;
    display: block;
  }
}