body.bookings-portal {
  .portal-page--new-booking {
    .main-content {
      margin-top: 20px;
      #main_booking_container {
        margin: 20px 0;
        .header-container {

        }
      }
    }
  }
}