.payment-amount-select {
  button {
    margin-bottom: 10px;
  }

}

.pay-amount {
  .apple-pay-button {
    width: 100%;
  }
}

#payment_buttons {
  button {
    width: 100%;
  }
}

#payment_options {
  display: flex;
  justify-content: end;
  gap: 10px
}