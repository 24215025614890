/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;

    /* Tooltip text */
    &--text{
        line-height: normal;
        width: max-content;
        max-width: 200px;
        background-color: #ebebeb;
        color: #000;
        text-align: center;
        padding: 5px 0;
        border-radius: 3px;
        position: absolute;
        z-index: 1;
        font-size: 14px;
        padding: 5px 10px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear .3s, opacity .3s;
    }

    &:hover &--text {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity .3s;
    }
}


