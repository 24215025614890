@import './../../../Variables';

.rbg-app {
  .booking-complete {
    .booking-info {
      background: white;
      border: #e9e9e9 solid 2px;
      border-radius: 4px;
      padding: 10px;
    }

    .deposit-button {
      margin: 10px 0;
    }

    button {
      width: 100%;
      padding: 10px 0;
      font-size: 13px !important;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .booking-complete {
    .notice--booking-summary {
      margin-bottom: 10px;
    }
  }
  .step--type--complete {
    .center {
      width: 100%;
    }
    @include tablet {
      .notice--booking-summary {
        width: 100%;
      }
      .share-booking {
        margin: 10px 0;
      }
    }
  }
  .pay-booking {
    width: 100%;
  }
  @include phone {
    .deposit-buttons {
      .btn {
        width: 100%;
        margin: 5px 0;
      }
    }
  }
  @include tablet {
    .deposit-buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      .btn {
        flex-grow: 6;
        margin: 5px;
      }
    }
  }
}