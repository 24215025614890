.bar-select {
  &.has-locator {
    display: flex;
    justify-content: space-between;

    .select2-wrapper {
      width: 90%;
    }
    margin-bottom: 20px;
  }
}