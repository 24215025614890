body.playhouse {
  .bar-finder {
    li {
      a{
        color: #FFF;
      }
    }
  }
}
.bar-finder {
  .closest-bar-list {
    list-style-type: none;

    li {
      list-style-type: none;

      a {
        display: flex;
        justify-content: space-between;
        color: #000;
        margin-bottom: 10px;
        border-bottom: #eee solid 1px;
        padding-bottom: 10px;

        span {
          strong {
            display: block;
          }
        }

        i {
          vertical-align: middle;
        }

        text-decoration: none;
      }
    }
  }
}