@import './../../Variables';

.portal-page.portal-page--edit-booking {
  .booking-detail {
    @include phone {
      padding: 0 10px;
    }
    @include tablet {
      min-height: 500px;
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      flex-grow: 2;
    }
    @include desktop {
      padding: 0;
    }
  }

  .notice--booking-postponed {
    background: white;
    margin-bottom: 10px;
  }
}
