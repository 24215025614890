// Mixin for content alignment
@mixin grid-align($align) {
  &.full .grid.align-#{$align} {
    @if $align == 'center' {
      align-items: center;
    } @else if $align == 'right' {
      align-items: flex-end;
    } @else {
      align-items: flex-start;
    }

    *:not(input) {
      text-align: $align;
    }
  }

  &.minimal .grid.align-#{$align} {
    @media only screen and (max-width: 575px) {
      @if $align == 'center' {
        align-items: center;
      } @else if $align == 'right' {
        align-items: flex-end;
      } @else {
        align-items: flex-start;
      }

      .package-count {
        @if $align == 'center' {
          justify-content: center;
        } @else if $align == 'right' {
          justify-content: flex-end;
        } @else {
          justify-content: flex-start;
        }
      }
    }

    *:not(input) {
      text-align: $align;
    }
  }
}

.pre-order:has(.products) {
  width: 100%;
}

.products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .product {
    width: 100%;
    min-height: 350px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #cecece;
    border-radius: 4px;

    @media screen and (max-width: 800px) {
      text-align: center;
    }

    &-backdrop {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 4px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
    }

    p {
      margin: 0.5em 0;
      text-align: center;
    }

    &__title {
      display: block !important;
      font-size: 20px !important;
      text-transform: uppercase;
      font-weight: bold;

      .popular {
        display: block;
        text-transform: uppercase;
        margin: 0;
        font-weight: 200;
        font-size: 14px;
        letter-spacing: 1px;
        color: #ffd315;
      }

      .fa-info {
        margin-left: 15px;
      }
    }

    &__price {
      font-weight: bold;
    }

    &__cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:not(.custom-package) {
        width: fit-content;
      }

      @media screen and (max-width: 800px) {
        align-items: center;
      }

      &.custom-package {
        .price {
          text-align: center;
          @media screen and (max-width: 575px) {
            width: 100%;
          }

          strong {
            font-size: 20px;
            border-bottom: 6px double;
          }
        }

        .package-count {
          margin: 8px auto;
          width: fit-content;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          gap: 8px;

          .field {
            width: 30%;
            padding-right: 0;
            flex-grow: unset;
            margin-bottom: 0;

            input {
              min-width: 32px;
            }
          }
        }
      }

      .btn {
        min-width: 40px;
        min-height: 40px;
        font-size: 14px;
        margin: 0;

        span i {
          font-size: 30px !important;
        }

        &:has(.price-checkbox) {
          border-radius: 4px;
          width: fit-content;
          font-size: 18px !important;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        .price-checkbox {
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 4px 8px;
        }
      }
    }

    @include grid-align(left);
    @include grid-align(center);
    @include grid-align(right);

    .grid {
      position: inherit;
      justify-content: space-between;
      height: 100%;
      flex-direction: column;
      align-items: center;

      &__item {
        width: fit-content;
        flex-grow: unset;

        &.item-description {
          .product__info {
            margin-top: 20px;
            font-size: 14px;
          }
        }
      }
    }
  }

  // Minimal Template
  .product.minimal {
    min-height: fit-content;
    background-image: none !important;

    .product__image {
      height: 125px;
      width: 125px;
      object-fit: cover;
    }

    @media screen and (min-width: 575px) {
      .product-backdrop {
        display: none;
      }
      .product__cta {
        min-width: 20%;
        max-width: 200px;
        width: 100%;
      }
      .grid__item {
        color: inherit;
      }
    }

    .product__cta {
      .custom-package {
        .price {
          width: 75%;
          text-align: center;
        }

        .package-count {
          margin: 8px 0 8px auto;
        }
      }
    }

    .grid {
      @media screen and (min-width: 575px) {
        gap: 16px;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  // Full Template
  .product.full {
    padding: 50px 20px;
    @media screen and (min-width: 500px) {
      background-size: 100%;
      padding: 60px 40px;
    }
  }
}