@import './../../../Variables';

.create-account {
  margin-top: 10px;

  h2 {
    font-size: 14px;
  }

  @include tablet() {
    .btn {
      margin-top: 20px;
    }
    text-align: center;
    margin: 0 auto;
  }

  .btn.btn--create-account {
    @include phone() {
      width: 100%;
    }
  }
}