.guest-management {
  padding: 10px;

  .add-guest-buttons {
    display: flex;
    margin: 0 -10px;

    button {
      width: 50%;
      margin: 0 10px;
    }
  }

  .guests {
    margin: 10px -10px;

    .guests__guest {
      background: #FFF;
      display: block;
      width: 100%;
      padding: 5px 0;
      height: 45px;
      border-collapse: collapse;
      border-bottom: #cecece solid 1px;

      &:first-child &:not(&.guest__guest--host) {
        border-top: #cecece solid 1px;
      }

      &.guest__guest--host {
        margin-bottom: 20px;
      }

      .guest__guest--name {
        float: left;
        margin-left: 20px;
        line-height: 40px;
      }

      .guest__guest--indicator {
        background: #bfbebe;
        border-radius: 18px;
        width: 100px;
        font-size: 12px;
        font-weight: 600;
        float: right;
        line-height: 35px;
        margin-right: 20px;

        &.status--complete {
          background: rgb(21, 213, 171);
          color: #FFF;
        }

        &.status--pending {
          background: rgb(238, 238, 238);
          color: #FFF;
        }

        &.status--no-activity {
          background: #E56C58;
          color: #FFF;
        }

        &.status--in-progress {
          background: #EEEEEE;
          color: #000;
        }
      }
    }
  }

  .notice.notice--host-not-added {
    margin: 30px 0;
  }
}