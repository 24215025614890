.allergens-menu-filters {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  padding: 0 20px;
  width: 100%;
  height: fit-content;

  @media screen and (min-width: 1200px) {
    max-width: 360px;
    padding: 20px;
  }

  .ui-filters, .dietary-options, .allergens {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    justify-content: center;
  }

  &-title {
    text-align: center;
    margin-top: 0;
  }

  .divider {
    margin: 16px 0;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid rgba(60, 60, 60, 0.1);
    margin: 8px 0;
    padding: 10px;
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.5);
    width: 200px;
    flex-direction: row;
    gap: 0;

    @media screen and (min-width: 1200px) {
      width: 300px;
    }

    .switch:has(input:checked) {
      background-color: #eb5a5e;
    }
  }
}