@import './../../Variables.scss';

button.datepicker-input {
  width: 100%;
  height: 40px;
  font-size: 16px;
  text-align: left;
  border: #cecece solid 2px;
  border-radius: 2px;
}

.react-datepicker-wrapper {
  z-index:99999;
}

.field--date {
  .field__element-wrap {
    position: relative;
  }

  .field__element-wrap:before {
    display: block;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    top: -1px;
    right: -1px;
    z-index: 1;
    pointer-events: none;
    background-clip: padding-box;
    content: "\f078";
    font-family: FontAwesome;
    font-size: 10px;
    border: 1px solid transparent;
    border-radius: 0 4px 4px 0;
    width: 50px;
    height: 50px;
    line-height: 48px;
  }
}