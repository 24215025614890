.deposit-request {
  .notice--booking-summary {
    strong {
      padding: 0 5px 0 0;
    }
  }
  #payment_buttons {
    button {
      width: 100%;
    }
  }
}