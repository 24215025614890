.share-link {
  margin-bottom: 10px;

  .field__element-wrap {
    position: relative;
    margin: 5px 0;

    button {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      background: #e8e8e8;
      padding: 10px;
      cursor: pointer;
    }
  }
}