
@import '../../../../../Variables';

.pre-order {
  .notice--pre-order-locked {
    margin-bottom: 10px;
    background: #FA8C24;
  }

  .pre-order--option {
    background: #fff;
    border: #cecece solid 1px;
    border-radius: 5px;
    padding: 20px 10px;
    margin: 0 0 10px 0;

    .pre-order--option--status {
      font-style: italic;

      .add-another {
        padding: 0 0 0 5px;
        text-decoration: none;
      }
    }

    .pre-order--option--title {
      h2 {
        float: left;
        width: 80%;
      }

      .tooltip {
        float: right;
      }
    }

    h2 {
      font-size: 18px;
      margin: 5px 0;
    }

    .pre-order--guest-row {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      margin: 5px 0;
      padding: 5px 0;
      border-bottom: #cecece solid 1px;

      &:last-child {
        border-bottom: none;
      }

      .pre-order--guest-row--name-choices {
        width: 100%;

        @include tablet {
          width: 60%;
        }

        .name {
          font-weight: bold;

          .no-name {
            color: #cecece;
            font-style: italic;
          }
        }

        .choices {
          padding: 10px 0;
          p {
            display: block;
            margin: 0 7px 0 0;

            &:after {
              content: ',';
            }

            &:last-child {
              &:after {
                content: '';
              }
            }
          }
        }
      }

      .pre-order--guest-row--buttons {
        width: 100%;

        @include tablet {
          width: 40%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
        }

        .btn--remove-guest {
          margin-right: 10px;
        }
      }
    }
  }
}

.pre-order--modal--add-edit-single {
  .notice {
    margin: 0 0 10px 0;
  }
}

.quantity-selector--icon {
  border: 1px solid #ccc;
  border-radius: 50%;
  margin: 0 10px;
  text-align: center;
  padding: 6px;
  color: darkgray;
  cursor: pointer;
  background: white;
}

.quantity-selector--number {
  border: 1px solid #ccc;
  margin: 0 10px;
  font-size: 16px;
  text-align: center;
  padding: 5px 10px;
  font-weight: bold;
  background: white;
}

.quantity-selector--disabled {
  cursor: not-allowed;
  background-color: #f4f4f4;
  color: #AAAAAA;
}

.text-success {
  color: #28a745!important;
}
.text-muted {
  color: #6c757d!important;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.cursor-pointer {
  cursor: pointer;
}
