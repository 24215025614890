.portal-page--login {
  // main resets
  main.container {
    width: 100%;
    max-width: 1500px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #000 !important;
    font-weight: bold !important;
  }

  .divider {
    border: none;
    border-bottom: 1px solid #cecece;
  }

  .allergens-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    padding: 20px;

    @media screen and (min-width: 1200px) {
      flex-direction: row;
      width: 95%;
      gap: 40px;
      margin: 0 auto;
    }

    &-list {
      width: 100%;

      &-inputs {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0 20px;
        color: #000;

        .form-row {
          padding: 0;

          &:first-of-type {
            margin-bottom: 16px;
          }
        }

        @media screen and (min-width: 800px) {
          flex-direction: row;

          .form-row {
            width: 50%;
          }
        }
      }

      &-menu-items {
        background-color: #fff;
        color: #000;
        border-radius: 8px;
        padding: 20px;
        list-style: none;

        .info {
          text-align: center;
        }

        .form-row {
          padding-bottom: 0;
          margin-bottom: 24px;

          .form-label {
            padding-left: 4px;
          }
        }

        .field {
          border: 1px solid #cecece;
          border-radius: 6px;
        }
      }
    }

    .disclaimer-btn {
      background-color: transparent !important;
      position: fixed;
      bottom: 20px;
      right: 20px;
      color: #ff5900;
      font-size: 30px;
    }
  }
}