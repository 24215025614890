button.btn {
  &.btn--circular {
    border-radius: 50%;

    span {
      //color: #FFF;

      i {
        //color: #FFF;
        font-size: 14px;
      }
    }
  }
}

.form-row {
  &.has-error {
    > label {
      color: #b31117;
    }

    > div {
      border: #b31117 solid 1px;
      border-radius: 4px;
    }
  }
}

body.revolution, body.decuba {
  .rbg-app, .rbg-modal {
    button.btn {
      &.btn--full-width {
        width: 100%;
      }

      &.btn--text {
        display: inline;
        background: none;
        text-transform: none;
        min-width: 0;
        width: auto;
        margin: 0;
        color: #191919;
        text-decoration: underline;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 2px;
        min-height: auto;

        &:hover {
          text-decoration: none;
        }

        &:before, &:after {
          width: 0;
          height: 0;
        }
      }

      &.btn--outline {
        &:hover {
          span {
            color: #000;
          }
        }
      }
    }
  }
}