@import '../../../../../Variables';

.pre-order--add-edit-guest {
  h3 {
    margin: 0 0 30px 0;
  }
  .pre-order--add-edit-guest--name {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .field {
      width: 49%;
    }
    @include phone {
      .field {
        width: 100%;
      }
    }
  }

  .booking-fieldsets {
    @include tablet {
      display: flex;
    }

    fieldset {
      border: #E9E9E9 solid 1px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      @include tablet {
        width: 50%;
      }
      .choice--choice {
        width: 100%;
      }
    }
  }
  .btn {
    float: right;

    &.btn--remove-guest {
      margin: 10px 0;
    }
  }
}