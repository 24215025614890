.rbg-app {
  .loading-icon {
    text-align: center;

    i {
      font-size: 80px;
      &:before {
        content: "\f013";
      }
    }
  }
}

body.revolution {
  .loading-icon {
    text-align:center;
    i {
      &:before {
        content: '';
      }
      display: inline-block;
      width: 50px;
      height: 50px;
      background: url('/assets/images/rev-logo.svg') no-repeat;
      background-size: 51px 51px;
    }
  }
}