@import './../../Variables';

body.decuba {
  font-family: "Gopher Medium", sans-serif;

  .field__element, .react-datepicker {
    font-family: "Gopher Medium", sans-serif;
  }

  a {
    color: #000;
    text-decoration: underline;
    font-weight: 600;

    &:hover {
      text-decoration: none;
    }
  }

}

#main_booking_container {
  > .notice {
    margin: 0 10px;
  }
}

.portal-page > .container {
  // height: 100%;
  @include desktop() {
    max-width: 1000px;
  }
}

button {
  border: 0;
}

#main_booking_container {
  > .notice {
    margin: 10px 20px;
  }

  .loading__block {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #cecece;
    opacity: 0.8;
    z-index: 100;

    .loading-icon {
      margin: 50px;
    }
  }
}

body {
  margin: 0;
  padding: 0;

  .portal-page {
    height: 100%;

    header {
      height: 80px;
      padding: 5px;

      .header-logo {
        display: block;
        float: left;
      }

      .user {
        float: right;
        font-size: 28px;
        line-height: 50px;

        a {
          color: #000;
          margin: 0 10px;
        }
      }
    }

    main {
      flex-grow: 2;
      @media only screen and (max-width: 800px) {
       width: 90%;
      }
    }

    footer {
      min-height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @include tablet {
        justify-content: flex-end;
      }

      .copy {
        margin-bottom: 50px;
      }
    }
  }
}

body.decuba, body.revolution {
  .step.step--user-data {
    .complete-booking {
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }
}

.step--user-data {
  h2 {
    width: 100%;
  }
}

body.decuba {
  .rbg-modal {
    .field--text, .field--select, .field--textarea, .field--date {
      border: #cecece solid 1px;
    }

    .field--checkbox {
      .field__sub-label::before {
        border: #000 solid 1px;
      }
    }
  }
}

body.bookings-portal.decuba {
  background-color: #ebddd3;

  .portal-page {
    height: 100%;

    header {
      .header-logo {
        margin: 5px;
      }

      .fa {
        color: #FFF;
      }

      .user {
        margin: 10px;
      }
    }

    .content-box {
      .content-box-title {
        background: #252632;
        text-align: center;
      }
    }


    .content-box {
      background-color: #F7F7F7;
      padding: 30px;
      border-radius: 4px;
      border: #e0e0e0 solid 1px;
    }

    footer {
      color: #110A27;
      margin-top: 20px;
      text-align: center;
    }

    header {
      background: #164464;

      .header-logo {
        width: 51px;
        height: 51px;
        background: url('/assets/images/logo-shield-blue.svg');
        background-size: 51px 51px;
      }
    }

    &--dashboard {
      header .header-logo {
        background-image: url('/assets/images/logo-shield.svg');
      }
    }

  }

  .btn.btn--outline {
    &:hover {
      color: #000;

      span {
        color: #000;
        text-decoration: underline;
      }
    }
  }

  .share-link .copy {
    //background-color: $orange;
  }

  .line-through-text {
    border-color: #164464;

    span {
      background: #164464;
      color: #fff;
      top: 10px;
      padding: 1px 6px 2px;
      border-radius: 4px;
    }
  }

  .portal-page--variant {
    header {
      //background: #f66250;

      .container {
        padding: 0;

        i, .header-logo {
          color: #FFF;
          height: 30px;
          width: 30px;
        }

        .header-logo {
          display: block;
          background: none;
          font-family: FontAwesome;
          font-size: 35px;
          line-height: 50px;
          text-decoration: none;
          color: #FFF;

          &:before {
            content: "\f060";
          }
        }
      }
    }
  }
}

body.revolution {
  .portal-page {
    footer {
      margin: 10px 0;
      color: #000;
      text-align: center;
      background: #edfc70;
    }

    .content-box {
      .content-box-title {
        background: #9c96ed; //linear-gradient(to right, #FA8C24, #CC3A60);
        text-align: center;
      }
    }

    &.portal-page--variant {
      header {
        background: #9c96ed;

        .container {
          i, .header-logo {
            color: #FFF;
          }

          .header-logo {
            display: block;
            background: none;
            font-family: FontAwesome;
            font-size: 35px;
            line-height: 50px;
            text-decoration: none;
            color: #FFF;

            &:before {
              content: "\f060";
            }
          }
        }
      }
    }

    &--dashboard, &--login {
      header .header-logo {
        transform: rotate(-15deg);
      }

      color: #fff;

      .no-bookings {
        p {
          color: #333333;
        }
      }
    }

    &--edit-booking {
      .main-content {
        background: rgba(255, 255, 255, 0.9);
        padding: 20px;
        margin-top: 40px;
        border-radius: 4px;
      }
    }

    .booking-listing {
      color: #000;
    }

    .booking-tabs {
      .booking-tabs--tabs {
        .booking-tabs--tab.active {
          background: #9c96ed;
        }
      }
    }

    .user {
     a {
        color: #fff;
      }
    }

    header {
      background: #9c96ed;

      .container {
        padding: 10px 10px;

        .header-logo {
          display: block;
          width: 51px;
          height: 51px;
          background: url('/assets/images/rev-logo.svg');
          background-size: 51px 51px;
          //transform: rotate(-15deg);
        }
      }
    }

    .content-box {
      padding: 30px;
      border-radius: 4px;
      .btn:not(.btn--register) {
        color: #fff !important;
      }
    }

    a.btn {
      //color: #000;
      line-height: 45px;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &.btn--outline {
        color: #000;
      }
    }

    a.link-button.default {
      display: block;
      width: 100%;
      padding: 10px 0;
      text-align: center;
      color: #000;
      text-decoration: none;
      font-weight: 600;
    }

    #payment_frame {
      margin-bottom: 10px;
      border: #cecece solid 1px;
      margin-top: 10px;
    }

    .deposit-request {
      button.deposit {
        width: 100%;
        text-align: center;
      }
    }
  }
}


.step {
  &.step--type--brunch {
    .btn--do-callback {
      margin: 10px 0;
      width: 100%;
    }
  }
}

.line-through-text {
  border-bottom: #cecece solid 1px;
  text-align: center;
  margin-bottom: 30px;

  span {
    position: relative;
    top: 12px;
    background: #F7F7F7;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
  }
}

body {
  .step {
    &.step--2 {
      .notice.notice--fully-booked, .notice.notice--cant-cater {
        .btn {
          margin: 10px 0;
          width: 100%;
        }
      }
    }
  }
}

.field--text .field__element {
  -webkit-appearance: none;
}

.step {
  max-height: 700px;
  overflow-y: auto;

  @include phone {
    max-height: unset;
  }

  @include tablet {
    body .portal-page {
      footer {
        justify-content: flex-end;
      }
    }
    
  }
}

body.edit-booking {
  #C4A_btn1, #C4A_btn2, #C4A_btn3, #C4A_btn4, #C4A_btn5 {
    @include phone() {
      bottom: 90px !important;
    }
  }
}