.copy-container {
  width: 100%;
  height: 400px;
  margin: 10px auto;
  border: #cecece solid 1px;
}

@media print {
  .copy-container {
    height: 100%;
  }
}

.contract-signature {
  text-align: center;

  .signature {
    border: #cecece solid 1px;
  }
  .btn {
    margin: 0 10px;
  }
}

.options {
  p {
    label {
      input {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 480px) {
  .option-buttons {
    button {
      width: 100%;
    }
  }
}

@media (min-width: 481px) {
  .option-buttons {
    button {
      margin-right: 10px;
    }
  }
}

.option-buttons {
  button {
    margin-bottom: 10px;
  }
}

@media (min-width: 480px) {
  .go-back-holder {
    p {
      margin: 5px 0;
    }
  }
}