.package-info {
  .quantity {
    button {
      border: 0;
      float: left;
      border: none;
      color: #FFF;
      font-size: 16px;
      border-radius: 30px;
      padding: 4px 7px;
      background: linear-gradient(to right, #19da9c, #00d5b9, #00cfd0, #00c7df, #06bee6);
      margin: 10px 4px;
    }

    input[type=number] {
      float: left;
      width: 50px;
      border: #cecece solid 2px;
    }
  }

  .remove-button {
    margin: 10px 0;
    background: none;
    width: 100%;
    border: #cc3a5f solid 2px;
    border-radius: 2px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
  }

  .item-choices {
    p {
      padding: 10px 0;
    }
    .btn {
      width: 100%;
      margin: 5px 0;
    }
    .option-choices__choice {
      padding: 10px 0;
      .field {
        margin: 5px 20px;
      }
    }
  }
}