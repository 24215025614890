.header-container {
  display: block;
  width: 100%;
  height: 40px;

  .header-nav, .header-title, .header-actions {
    float: left;
  }

  .header-nav {
    width: 25%;
    min-width: 15%;

    button {
      border: 0;
      background: none;
      color: #FFF;
      font-size: 18px;
      margin-top: 7px;
      cursor: pointer;
    }
  }

  .header-title {
    width: 50%;
    color: #FFF;
    text-transform: uppercase;
    line-height: 40px;
    text-align: center;
  }

  .header-actions {
    width: 25%;
    min-width: 15%;
    text-align: right;

    button {
      border: 0;
      background: none;
      color: #FFF;
      font-size: 18px;
      margin-top: 7px;
    }
  }
}