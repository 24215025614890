body.bookings-portal .portal-page.portal-page--login,  body .portal-page.portal-page--register, body .portal-page.portal-page--verify, body .portal-page.portal-page--join-booking,  body .portal-page.portal-page--join-booking-guest{
  header {
    background: none;
    text-align: center;
    height: 150px;

    .container {
      .header-logo {
        margin: 0 auto;
        float: none;
        background-size: 75px 75px;
        height: 75px;
        width: 75px;
      }

      padding: 20px 0;
    }
  }

  .intro {
    .intro__content {
      text-align: center;
      font-weight: 300;
    }
  }
  .login-form {
    .btn {
      width: 100%;
    }
  }
  .login-options {
    margin: 0 20px;
    .btn--google-sign-in, .btn--facebook-sign-in {
      width: 100%;
      margin: 5px;
    }

    .btn--google-sign-in {
      background: white;
      color: #000;
      -webkit-box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
      position: relative;

      i {
        position: absolute;
        left: 20px;
        top: 10px;
      }
    }

    .btn--facebook-sign-in {
      background: blue;
    }
  }

  .content-box.content-box--login, .content-box.content-box--register, .content-box.content-box--verify {
    margin: 0 20px 0 20px;
    .intro {
      margin: 0 0 10px 0;
    }
    .field {
      width: 100%;
      margin-bottom: 10px;
    }

    .btn {
      width: 100%;
    }

    button {
      margin-top: 10px;
    }

    .notice {
      margin: 10px 0;
    }

    .btn--register {
      margin: 10px 0;
      max-width: 100%;
      padding: 0;
    }
  }

  .forgot-password {
    margin-top: 20px;
    text-align: center;
  }

  .login__password {
    position: relative;

    a {
      float: right;
      position: absolute;
      right: 10px;
      top: 10px;
      text-decoration: underline;
      color: #000;
    }
  }
}