$fold-phone-width: 280px;
$half-phone-width: 360px;
$phone-width: 600px;
$tablet-width: 768px;
$desktop-width: 1024px;
@mixin fold_phone() {
  @media (max-width: $fold-phone-width) {
    @content;
  }
}

@mixin half_phone() {
  @media (max-width: $half-phone-width) {
    @content;
  }
}

@mixin phone() {
  @media (max-width: $phone-width) {
    @content;
  }
}

@mixin only_tablet() {
  @media (min-width: #{$phone-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: #{$phone-width}) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin retina {
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}