.menu-item-card {
  display: flex;
  margin-bottom: 10px;
  border: 1px solid #616161;

  &-banner {
    width: 20px;
    background-color: #E8E8E8;

    &.intolerance {
      background-color: #eb5a5e;
    }
  }

  .menu-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;

    section {
      display: flex;
      flex-direction: column;
      max-width: 775px;

      @media screen and (min-width: 1200px) {
        max-width: 500px;
      }

      .dietary {
        img {
          width: 20px;
          margin-right: 8px;
          margin-bottom: 4px;
        }
      }
    }

    @media screen and (min-width: 1000px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      margin: 0 0 10px;

      strong {
        text-transform: uppercase;
        font-size: 1.1rem;
      }
    }

    &-description {
      font-size: .90rem;
      margin: 0;
    }

    .divider {
      width: 100%;
    }

    &-contains, &-may-contain {
      margin: 0 0 10px;
      word-break: break-word;
      font-size: .90rem;

      .highlighted {
        color: #eb5a5e;
        text-decoration: underline;
        font-weight: bold;
      }

      strong {
        text-transform: uppercase;
      }

      span {
        font-style: italic;
      }
    }

    &-show-ingredients {
      min-width: 195px;
      margin-top: 10px;
      padding: 16px 24px;
      background-color: #6675F8;
      cursor: pointer;
      color: #fff;
      font-weight: bold;
      font-family: inherit;
      border-radius: 4px;

      @media screen and (min-width: 1000px) {
        margin-left: 20px;
      }
      &:hover {
        background-color: #FF9519;
      }
    }
  }

}