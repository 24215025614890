@import './../../../Variables.scss';
#main_booking_container {
  position: relative;
  padding-bottom: 80px;

  .deposit-bar {
    width: 100%;
    background: #FFF;
    border-top: #cecece solid 1px;
    padding-bottom: 10px;
    .notice {
      margin: 10px;
    }
    .info-button {
      background: #FFF;
      border-radius: 20px;
      width: 20px;
      border: #cecece solid 1px;
      padding: 2px;
      margin: 0;
      background: none;
      outline: none;

      &:active, &:hover {
        outline: none;
      }
    }

    .deposit-values {
      display: flex;
    }

    .deposit-inner {
      margin: 10px;

      .amounts {
        float: left;
        flex-basis: 50%;
        padding-right: 10px;

        .deposit-main {
          font-size: 20px;
          font-weight: bold;
        }

        .deposit-sub {
          font-size: 12px;
          color: #bbb;
        }
      }

      &.hide-deposit {
        button.btn--continue {
          float:none;
        }
      }

      button.btn--continue {
        flex-basis: 50%;
        flex-grow: 1;
        float: right;
        padding: 10px 0;
        text-transform: uppercase;
      }
    }
  }
}

body {
  .deposit-bar-holder {
    .allergy-status {
      p {
        margin: 3px 0;
      }
      display: block;
      height: 150px;
      .field {
        flex-grow: 6;
        display: inline-block;
        width: 20%;
      }
      @include tablet {
        width: 100%;
        margin-bottom: 10px;
        p {
          display: block;
          width: 100%;
          margin: 10px 0;
        }
        .field {
          margin: 5px 0;
          width: 20%;
          &.field--sms_opt_in .field__element-wrap label {
            //text-align:center;
          }
          &.field--phone_opt_in .field__element-wrap label {
            //text-align:right;
          }
        }
      }
    }
  }
}