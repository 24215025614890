@import './../Variables';

.portal-page--join-booking {
  .content-box--join-booking {
    .btn {
      margin: 10px 0;
    }

    @include phone {
      .field {
        width: 100%;
      }
    }
    @include tablet {
      form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .field {
          flex-grow: 1;
          width: 31%;
          margin: 5px;
        }
      }
    }
  }

  .btn {
    display: block;
    width: 100%;
  }

  .join-booking--details {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;

    .field {
      flex-grow: 1;
    }
  }

  .join-booking--opt-ins {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px;
    flex-wrap: wrap;

    p.terms-text {
      display: block;
      width: 100%;
      margin-bottom: 0;
    }
    .field {
    }
  }
}