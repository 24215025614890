@import './../../../Variables';

.portal-page {
  .booking-listing {
    background: #FFF;
    border: #cecece solid 1px;
    border-radius: 4px;
    margin: 10px;
    padding: 10px 10px 0 10px;
    position: relative;

    .booking-info.grid {
      .grid__item {
        width: 50%;
      }
    }
    
    .row .booking-info {
      font-size: 14px;
    }
    
    .booking-info {
      margin: 20px 0;
    
      &.grid {
        align-items: center;
        justify-content: space-between;  
        flex-wrap: wrap;
      }
    
      .booking-info__item {
        width: 50%;
    
        span {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 14px;
          margin: 4px 0;
          vertical-align: top;
    
          img {
            margin-right: 5px;
          }
        }
      }
    
      .booking-info--people,
      .booking-info--balance {
          span {
            justify-content: flex-end;
        }
      }
    }

    .booking-listing--attendee-type {
      position: absolute;
      right: 0px;
      top: 0px;
      display: block;
      width: auto;
      color: #FFF;
      font-weight: bold;
      font-size: 12px;
      padding: 5px;
      border-radius: 0 3px 0 4px;

      &.type--guest {
        background: orange;

        &:after {
          content: 'Guest';
        }
      }

      &.type--lead-booker {
        background: #4ca586;

        &:after {
          content: 'Lead Booker';
        }
      }

      &.type--co-host {
        background: yellow;
        color: #000;

        &:after {
          content: 'Co-Host';
        }
      }
    }

    .booking-listing--link {
      display: block;
      margin: 10px -10px 5px -10px;
      border-top: #cecece solid 1px;
      padding: 5px 10px 0px 10px;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      text-transform: uppercase;
      color: #000;
      font-weight: 600;
      text-decoration: none;
    }

    .booking-listing--status {
      width: 100%;
      text-align: center;
      padding: 10px 0;
      color: #FFF;
      font-size: 13px;
      font-weight: 600;

      &.booking-listing--status--success {
        background: #4ca586;
      }

      &.booking-listing--status--warning {
        background: orange;
      }

      &.booking-listing--status--error, &.overdue {
        background: red;
      }
    }

    .booking-listing--booking-name {
      display: block;
      width: 100%;
      font-weight: bold;

      @include phone {
        width: 70%;
      }
    }

    .booking-listing--ref-code {
      display: block;
      width: 100%;
      font-size: 12px;
      color: #8e8e8e;
    }

    &.bookings-listing--collapsed {
      padding: 0;

      .booking-listing--booking-name {
        float: left;
        width: 80%;
        line-height: 30px;
        font-size: 14px;
        margin: 0;
        padding: 5px 10px;
      }

      i {
        line-height: 30px;
        padding: 5px 10px;
        float: right;
      }
    }
  }
}