@import './../../../../../Variables';

.edit-guest {
  .intro--guest-name {
    .intro__title {
      font-size: 18px;
    }
  }

  .edit-guest__details {
    .edit-guest__details__item {
      padding-left: 25px;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 0 2px;

      &.details--phone {
        background-image: url('/assets/icons/Phone.svg');
      }

      &.details--email {
        background-image: url('/assets/icons/Email.svg');
      }

      &.details--balance {
        background-image: url('/assets/icons/Payment.svg');
      }
      &.details--host {
        background-image: url('/assets/icons/Host.png');
      }
    }
  }

  .remove-guest {
    .btn {
      width: 100%;
      margin: 10px 0;
      @include tablet {
        width: 80%;
      }
      @include desktop {
        width: 60%;
      }
    }
  }

  .send-reminder {
    .btn {
      margin: 10px 0;
      width: 100%;
      @include tablet {
        width: 80%;
      }

      @include desktop {
        width: 60%;
      }
    }
  }
  .edit-guest-details {
    .btn {
      width: 100%;
      margin: 10px 0;
    }
  }
}