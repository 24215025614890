.modal--modal {
  max-width: 600px;
  overflow: auto;

  @media screen and (max-width: 400px) {
    width: 100% !important;
  }
}

.ingredients {
  &-title {
    font-size: 1.4em;
    margin-top: 0 !important;
    text-align: center;
  }

  .ingredients-card {
    display: flex;
    margin-bottom: 10px;
    border: 1px solid #616161;

    &-banner {
      width: 20px;
      background-color: #E8E8E8;

      &.intolerance {
        background-color: #eb5a5e;
      }
    }

    &_body {
      width: 90%;
      margin: 0 auto;

      &-name {
        margin: 8px 0;
      }

      .divider {
        margin-top: 0;
      }

      &-group {
        p {
          margin: 0 0 10px;
        }

        &-contains, &-may-contain {
          .highlighted {
            color: #eb5a5e;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
