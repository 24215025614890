.form-row {
    padding-bottom: 2em;
}

.menu-list {
    list-style: none;
    padding-left: 0;

    .menu-item {
        margin-bottom: 1.5em;
        display: flex;
        align-items: flex-start;

        .fa-info {
            width: 25px;
            height: 24px;
            border: 1px solid;
            border-radius: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
        }

       .menu-item-wrapper {
           width: 100%;

            p {
                margin-top: 0;
            }

            .menu-item-divider {
                height: 1px;
                background-color: rgba(0, 0, 0, 0.2);
            }
       }
    }
}