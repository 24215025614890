@import './../../../Variables';

#main_booking_container {
  .notice {
    &.notice--booking-payment-required {
      &.desktop {
        @include phone {
          display: none;
        }
      }
      &.mobile {
        @include tablet {
          display: none;
        }
      }
      margin-bottom: 10px;
    }
    &.notice--booking-summary-short  {
      position: relative;

      p {
        margin-top: 0;
        @include phone {
          margin-right: 90px;
        }
      }

      &.desktop {
        @include phone {
          display: none;
        }
        display: block;
        width: 100%;
        border: 0;
        background: 0;
        padding: 0;
        margin-bottom: 10px;

        p {
          padding: 0 10px 0 0;
        }

        button {
          float: right;
        }
      }

      &.mobile {
        @include tablet {
          display: none;
        }

        .btn {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }
  }
}

.step {
  .notice {
    width: 100%;
  }
}