@import './../../../Variables';

.edit-booking-details {
  .edit-booking-details__fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .field {
      flex-grow: 6;
      // margin: 10px;
      @include phone {
        width: 100%;
      }
      @include only_tablet {
        width: 100%;
      }

      &.field--people {
        position: relative;

        .field__element-wrap {
          &:after {
            content: 'Guests';
            position: absolute;
            right: 10px;
            top: 30%;
            color: #cecece;
          }
        }
      }
    }
  }

  .notice.notice--new-time {
    .btn {
      margin: 5px;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .edit-booking-details__fields {
    @include phone() {
      padding-bottom: 200px;
    }
  }
}