.dob-picker {
  position: relative;
  * {
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &_error {
    font-size: 12px;
    color: #DE3226;
    margin-top: 0.25em;
  }

  &_pills {
    background: #fff;
    display: flex;
    gap: 30px;
    flex-wrap: nowrap;

    .field {
      margin: 0;

      &:has(input.open) {
        z-index: 2;
      }
    }

    &-input {
      text-align: center;
      &.focus {
        border-color: #8a8a8a;
      }
    }
  }

  &_backdrop {
    display: none;
    z-index: 1;
    &.active {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }

  &_container {
    position: relative;
    border: 1px solid #d7d7d7;
    border-radius: 0.5rem;
    width: 300px;
    height: 330px;
    background-color: #fff;
    z-index: 2;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-arrow {
      border-top: 1px solid #d7d7d7;
      border-left: 1px solid #d7d7d7;
      background-color: #fff;
      transform: translate(-50%,-50%) rotate(45deg);
      height: 16px;
      width: 16px;
    }

    &-title {
      margin: 8px 0;
    }

     &_edit {
       width: 100%;
       display: flex;
       justify-content: space-between;

       &-previous {
         margin-top: 4px;
         padding: 4px 8px;
         color: #2d3748;
         background-color: #fff;
         cursor: pointer;

         i {
           margin-right: 10px;
         }

         &:hover {
           outline: none;
           box-shadow: 0 0 5px 2px rgba(59,130,246,.5);
           border-radius: 5px;
         }
       }
     }

    &-options {
      height: calc(300px - 70px);
      width: 100%;
      padding: 8px 0 0;

      &_years, &_months, &_days {
        height: calc(100% - 10px);
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 10px;
        padding: 5px 5px 5px;

        &-button {
          padding: 0.8rem 0;
          z-index: 0;
          border-bottom: 1px solid #e2e8f0;
          color: #2d3748;
          background-color: #fff;
          font-size: .85em;
          cursor: pointer;

          &:hover, &.selected {
            outline: none;
            z-index: 10;
            box-shadow: 0 0 5px 2px rgba(59,130,246,.5);
            border-radius: 5px;
          }
        }
      }

      &_years {
        &-group {
          width: 100%;
          margin: 16px 0;
          text-align: center;
          &:nth-child(1) {
            margin-top: 0;
          }
        }
        &-button {
          width: 25%;
          &:not(:nth-of-type(4n)) {
            border-right: 1px solid #e2e8f0;
          }
        }
      }

      &_months {
        &-button {
          width: 33%;
          &:not(:nth-of-type(3n)) {
            border-right: 1px solid #e2e8f0;
          }
        }
      }

      &_days {
        &-button {
          width: 20%;
          &:not(:nth-of-type(5n)) {
            border-right: 1px solid #e2e8f0;
          }
        }
        &:has(&-notice) {
          justify-content: center;
        }
      }
    }
  }
}