body {
  .portal-page {
    &.portal-page--dashboard {
      .intro {
        text-align: center;
      }
      .btn--add-booking {
        margin: 0 auto;
      }
      .no-bookings {
        margin: 20px auto;
      }
      .btn.btn.btn--add-booking {
        margin: 10px auto;
      }
    }
  }
}