@import './../../Variables';
.timeslot {
  margin: 3% 3% 0;
  background: none;
  border: none;
  float: left;

  color: #FFF;
  text-align: center;
  padding: 0;
  min-width: 44%;
  width: 44%;
  background: #1dbf8b;
  border-radius: 4px;
  @include tablet {
    margin: 0.7%;
    width: 20%;
    min-width: 20%;
    flex-grow: 2;
  }
  @include fold_phone {
    width: 42%;
  }

  span {
    padding: 4px 8px;
  }

  &.timeslot--inactive {
    background: #cecece;

    span {
      text-decoration: line-through;
      color: #727272;
    }

    span:last-child {
      color: #FFF;
    }
  }

  &.timeslot--fallback {
    background: #cecece;

    span {
      text-decoration: line-through;
      color: #727272;
    }

    span:last-child {
      color: #FFF;
    }
  }

  &.timeslot--stacked {
    &.timeslot--inactive {
      span:last-child {
        background: #b6b5b5 !important;
      }
    }
    &.timeslot--fallback {
        background: #1DBF8B !important;
      span {
        background: none !important;
      }
      span:last-child {
        text-decoration: none !important;
      }
    }

    span {
      display: block;
      width: 100%;
    }

    span:last-child {
      background: #17926c;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      @include phone {
        font-size: 11px;
        padding: 0;
      }
      @include half_phone {
        font-size: 9.5px;
      }
    }
  }
}

.time-slot-holder {
  .timeslot:not(.timeslot--deselected) {
    opacity: 1;
    border: 2px solid red;
    border-radius: 4px;
  }
  .timeslot--deselected {
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    span {
      text-decoration: none;
      color: white;
    }

    span:last-child {
      background: #17926c;
    }
  }
}

body.revolution {
  .time-slot-holder {
    .timeslot--deselected,
    .timeslot--stacked {
      span:last-child {
        background: #3d8a54;
      }
    }
  }
}