@import './../../../Variables.scss';
body {
  .step--user-data {
    .opt-ins {
      p {
        margin: 3px 0;
      }
      display: block;
      @include tablet {
        width: 100%;
        margin-bottom: 10px;
        p {
          display: block;
          width: 100%;
          margin: 10px 0;
        }
        .field {
          margin: 5px 0;
          width: 20%;
          &.field--sms_opt_in .field__element-wrap label {
            //text-align:center;
          }
          &.field--phone_opt_in .field__element-wrap label {
            //text-align:right;
          }
        }
      }
    }
  }
}