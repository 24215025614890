.rbg-app, .rbg-modal {
  .share-booking {
    margin-top: 10px;

    p {
      margin: 20px 0;
    }

    .share-links {
      width: 100%;
      display: block;
      margin: 10px auto;
      text-align: center;

      a {
        display: inline-block;
        width: 40px;
        height: 40px;
        padding: 5px;
        text-align: center;
        margin: 0 5px;
      }

      .whatsapp {
        background: green;
        color: white;
        font-size: 30px;
        line-height: 30px;
      }

      .facebook {
        background: blue;
        color: #fff;
        font-size: 30px;
        line-height: 30px;
      }

      .link {
        background: orange;
        color: #FFF;
        font-size: 30px;
        line-height: 30px;
      }

      .calendar {
        background: gray;
        color: #FFF;
        font-size: 30px;
        line-height: 30px;
        cursor: pointer;
      }
    }
  }
}