.notice.notice--terms-conditions {
  background: none;
  border: none;
  width: 100%;
  margin: 10px 0;
}
.notice.notice--no-show-message {
  margin: 0 0 10px 0;
}

div.notice.notice--covid-terms {
  p {
    margin: 5px 0;
  }
}