.field--no_people {
  .field__element-wrap {
    position: relative;

    .no_people-buttons {
      position: absolute;
      top: 10px;
      right: 20px;

      button:first-of-type {
        margin-right: 10px;
      }
      button {
        display: inline;
      }
    }
  }
}