@import './../Variables';

.portal-page--clubsoc {
  .already-registered, .btn-holder {
    margin: 10px auto;
    max-width: 400px;
    .btn {
      width: 100%;
    }
  }
  @include phone() {
    .already-registered {
      margin: 10px;
    }
  }

  .card-register {
    @include phone {
      margin: 10px;

    }

    .notice, .field, .btn-holder {
      margin: 10px auto;
      max-width: 400px;
    }

    .btn {
      width: 100%;
    }
  }
}

.soc-register-link {
  text-align: center;
  margin-top: 60px;
}
