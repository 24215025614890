.portal-page--edit-booking {
  .booking-paid {
    text-align: center;
    p {
      font-size: 13px;
      font-weight: 600;
    }
  }
  .edit-booking__payment {
    h2 {
      margin-top: 0;
    }

    .payment-summary {
      border-bottom: #cecece solid 1px;
      margin: 0 -10px;
      padding: 0 10px 10px 10px;
      .payment-summary__item {
        font-size: 13px;
        font-weight: 600;
        padding-left: 25px;
        margin-right: 20px;
        line-height: 15px;
      }

      .payment-summary--balance {
        background: url('/assets/icons/Total-Package.svg') no-repeat 0px -3px;
        background-size: 18px 18px;
      }

      .payment-summary--paid {
        background: url('/assets/icons/Payment.svg') no-repeat 0px -3px;
        background-size: 18px 19px;
      }
    }
  }

  .edit-bookings__payment-options {
    border-collapse: collapse;
    border: #cecece solid 1px;
    border-radius: 4px;
    margin: 20px 0;
    background: #FFF;

    .payment-option {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px 10px 10px 0 ;
      border-bottom: #cecece solid 1px;
      margin: 0;
      text-align:left;
      background: transparent;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      .payment-option__info {
        display: inline-block;
        width: 80%;
        font-size: 14px;

        .payment-option__title {
          display: block;
          text-align: left;
          font-weight: 600;
        }

        .payment-option__desc {
          display: block;
          text-align: left;
        }
      }

      .payment-option__indicator {
        display: inline-block;
        width: 20px;
        margin: 0 20px;
        height: 20px;
        background: url('/assets/icons/RadioUnchecked.svg') no-repeat;
        background-size: 20px 20px;
      }

      &.payment-option--selected {
        .payment-option__indicator {
          background: url('/assets/icons/RadioChecked.svg') no-repeat;
          background-size: 20px 20px;
        }
      }
    }
  }
}