@import '../../../../../Variables';

.add-guest {
  .add-guest__fields {
    margin: 20px -20px;
    border-bottom: #cecece solid 1px;

    .field {
      margin: 15px 20px;
    }
  }

  .add-guest__allergens {
    .allergen-list {
      display: flex;
      flex-wrap: wrap;

      .field {
        flex-grow: 3;
        width: 50%;
        @include tablet {
          width: 25%;
        }
      }
    }
  }
}