.error {
  background: rgb(206, 142, 142);
  border: rgb(180, 1, 1) solid 2px;
  border-radius: 2px;
  margin: 10px 0;
  padding: 5px;
  color: #FFF;
}

.payment-response {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px 0;

  i {
    font-size: 25px;
    float: left;
    display: block;
    width: 10%;
    margin: 8px 0;
  }

  p {
    width: 80%;
    margin: 0;
    font-size: 13px;
    float: left;
  }

  &.success {
    i {
      color: #FFF;
    }

    background: #7ec67e;
    border: #035503 solid 1px;
    border-radius: 2px;
    color: #FFF;
  }

  &.error {
    i {
      color: #b31117;
    }

    background: #d99191;
    border: #b31117 solid 1px;
    border-radius: 2px;
  }
}