@import './../Variables';

.grid {
  display: flex;
  flex-direction: row;

  .grid__item {
    flex-grow: 6;
  }
}

@include phone {
  .field {
    .field__element {
      font-size: 16px;
    }
  }

}

.step {
  > h2 {
    margin-top: 0;
  }
}

@include tablet {
  .field {
    .field__element {
      font-size: 16px;
    }
  }
  .step {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .field {
      width: 50%;
      padding-right: 10px;
      flex-grow: 6;
      display: inline-block;
    }
  }
}

#main_booking_container {
  .notice.notice--collect-details {
    .center {
      text-align: center;
    }

    .btn {
      display: block;
      margin: 10px auto;
    }
  }

  &.has-pre-order-message.has-allergen-message {
    padding-bottom: 180px;
  }

  &.has-pre-order-message, .has-allergen-message {
    padding-bottom: 130px;
  }

  @include phone() {
    &.has-pre-order-message.has-allergen-message {
      padding-bottom: 250px;
    }
    &.has-pre-order-message, .has-allergen-message {
      padding-bottom: 180px;
    }
  }
}

.step--time-select {
  .btn--do-callback {
    width: 100%;
    margin: 5px 0;
  }

  .christmas-alternative {
    display: block;
    width: 100%;

    .notice {
      margin-top: 10px;

      .btn {
        margin: 0 10px 0 0;
      }
    }
  }
}


.step--pre-booking-info {
  .field--covid_business_meeting {
    width: 100%;

    img {
      width: 20px;
      height: 20px;
      line-height: 20px;
      vertical-align: middle;
    }
  }
}


.debug-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .debug {
    width: 25%;
    padding: 4px;

    &.larger {
      width: 50%;
    }

    .debug-title {
      font-weight: bold;
      font-size: 12px;
    }

    .debug-value {
      font-size: 12px;
      word-break: break-all;
      overflow: scroll;
      max-height: 75px;
    }
  }
}

.step {
  .notice.notice--standing-space {
    margin-top: 10px;
  }
}

.notice.notice--standing-space {
  display: flex;
  line-height: 25px;
  justify-content: center;
  vertical-align: middle;
}

.field--checkbox {
  label {
    img {
      vertical-align: middle;
    }
  }
}

.field--special_requirements {
  width: 100% !important;
  margin-bottom: 10px;
}

.step--99, .step--fallback, .step--user-data {
  .field--time {
    width: 100% !important;
  }
}

.type-select--info {
  margin-top: 10px;
  .field {
    width: 100%;
    font-size: inherit;
  }
}