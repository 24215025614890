.booking-guest {
  h1.guest-name {
    border: #e0e0e0 solid 1px;
    background: #FFF;
    padding: 5px 25px;
    border-radius: 4px;
  }

  .content-box.content-box--guest-variation {
    background: red;

    .guest-variation--info {
      margin-bottom: 10px;

      img {
        float: left;
        max-width: 20%;
        margin: 0 10px 10px 0;
      }

      p.description {
        margin: 0 0 0 10px;
      }
    }
  }
}