@import './../../../Variables';

.re-book__change-date {
  .change-date__inputs {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    justify-content: space-between;
    @include phone {
      flex-wrap: wrap;
    }
    .field {
      width: 48%;
      @include phone {
        width: 100%;
      }
    }
  }

  .btn--complete, .btn--go-back {
    width: 48%;
    margin: 10px 1%;
    @include phone {
      width: 100%;
    }
  }
}