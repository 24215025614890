.edit-booking__packages {
  h1 {
    font-size: 23px;
  }

  .booking-option {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin: 10px;

    .booking-option__image {
      width: 20%;
      border-radius: 5px;
    }

    .booking-option__edit {
      width: 10%;

      i {
        font-size: 25px;
        margin: 20px 0;
      }
    }

    .booking-option__details {
      width: 70%;
      padding: 0 10px;
      margin: 0;

      color: #0F0426;

      .booking-option__details__title {
        a {
          color: #0F0426;
          font-weight: 600;
          text-decoration: none;
          line-height: 25px;

          img {
            height: 23px;
            padding-left: 5px;
            vertical-align: top;
            padding-top: 0;
          }
        }
      }

      .booking-option__details__qty {
        font-size: 14px;

        .booking-option__details__price {
          color: #E56C58;
        }
      }
    }
  }
}